import React from "react";
import "./style.css";
import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { TbCircuitResistor } from "react-icons/tb";
import { MdComputer } from "react-icons/md";

import { dataportfolio, meta } from "../../content_option";

export const Portfolio = () => {
  const [portScrolled, setPortScrolled] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [isAnimationActive, setIsAnimationActive] = useState(
    window.innerWidth > 600
  );

  useEffect(() => {
    function handleResize() {
      setIsAnimationActive(window.innerWidth > 1200);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const port = document.getElementById("portfolio");
    const header = document.getElementById("header");
    const home = document.getElementById("home");
    const projectBoxes = document.getElementById("projectBoxes");

    function handleScroll() {
      const portPos = port.getBoundingClientRect().top + window.scrollY;
      const headerHeight = header.getBoundingClientRect().height;
      const projectBoxesPos =
        projectBoxes.getBoundingClientRect().top + window.scrollY;
      console.log(projectBoxesPos);
      const homePos = home.getBoundingClientRect().top + window.scrollY;
      const headerPos = header.getBoundingClientRect().top + window.scrollY;

      // console.log(headerHeight + window.scrollY);
      if (!isAnimationActive) {
        // hardware.style.fontSize = window.innerWidth * 0.3;
        // software.style.fontSize = window.innerWidth * 0.3;

        return;
      }

      if (window.scrollY > portPos - 0.4 * window.innerHeight) {
        setPortScrolled(true);
        setOpacity(0);
        // console.log("true");
      } else {
        setPortScrolled(false);
        setOpacity(1);
        // console.log("false");
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAnimationActive]);

  function centerText(portScrolled) {
    const hardware = document.querySelector(".portfolio-section-hardware");
    const software = document.querySelector(".portfolio-section-software");
    // const software = document.querySelector(".po")

    // Set up transitions
    hardware.style.transition =
      "transform 0.3s ease, font-size 0.1s ease-in-out";
    software.style.transition =
      "transform 0.3s ease, font-size 0.1s ease-in-out";

    const handleTransitionEnd = (event) => {
      if (event.propertyName === "font-size") {
        // Check if the font-size transition has ended
        const hardwareParentWidth = hardware.parentElement.offsetWidth;
        const softwareParentWidth = software.parentElement.offsetWidth;
        const hardwareWidth = hardware.offsetWidth;
        const softwareWidth = software.offsetWidth;
        const hardwareCenterOffset =
          hardwareParentWidth / 2 - hardwareWidth / 2;
        const softwareCenterOffset =
          softwareParentWidth / 2 - softwareWidth / 2;

        // Apply new transformations including the new centering
        hardware.style.transform = `translateX(${hardwareCenterOffset}px) `;
        software.style.transform = `translateX(${-softwareCenterOffset}px) `;

        // Optionally remove the listener if it's no longer needed after the adjustment
        hardware.removeEventListener("transitionend", handleTransitionEnd);
        software.removeEventListener("transitionend", handleTransitionEnd);
      }
    };

    if (portScrolled) {
      // Add event listener
      hardware.addEventListener("transitionend", handleTransitionEnd);
      software.addEventListener("transitionend", handleTransitionEnd);

      // Trigger style changes
      hardware.style.fontSize = "5rem"; // Increase font size
      // hardware.style.transform = "translateY(-10px)";
      software.style.fontSize = "5rem"; // Increase font size
      // software.style.transform = "translateY(-10px)";
    } else {
      // Reset styles
      hardware.style.fontSize = "3rem"; // Reset to original font size
      hardware.style.transform = "translateX(0) translateY(0)";
      software.style.fontSize = "3rem"; // Reset to original font size
      software.style.transform = "translateX(0) translateY(0)";

      // Clean up listener if no transition is going to happen
      hardware.removeEventListener("transitionend", handleTransitionEnd);
      software.removeEventListener("transitionend", handleTransitionEnd);
    }
  }

  useEffect(() => {
    centerText(portScrolled);
  }, [portScrolled]); // Dependency on portScrolled

  return (
    <div
      section
      id="portfolio"
      className="portfolio portfolio_background text-center"
    >
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title> Portfolio | {meta.title} </title>{" "}
            <meta name="description" content={meta.description} />
          </Helmet>
          <Row className="mb-1 mt-1 pt-md-3">
            <h1
              className={`port_title display-4 mb-4 
              }`}
              style={{ opacity: opacity, transition: "opacity 0.5s ease" }}
            >
              {" "}
              Projects{" "}
            </h1>
          </Row>
          <div className="arrow-grid-container">
            <div>
              <img
                className="hardware_arrow"
                src="red_arrow.png"
                style={{ opacity: opacity, transition: "opacity 0.5s ease" }}
              ></img>
            </div>
            <div>
              <img
                className="software_arrow"
                src="red_arrow.png"
                style={{ opacity: opacity, transition: "opacity 0.5s ease" }}
              ></img>
            </div>
          </div>
          <div id="hardwareSoftware" className="title-grid-container">
            <div className="hardware">
              <h1 className={`mb-4 portfolio-section-hardware `}>
                Hardware <TbCircuitResistor />
              </h1>
            </div>
            <div className="software">
              <h1
                className={`mb-4 portfolio-section-software
                }`}
              >
                Software <MdComputer />
              </h1>
            </div>
          </div>

          <div id="projectBoxes" className="grid-container text-left">
            <div>
              <h1 className="title">Four Bar Linkage</h1> {/* FIRST PROJECT */}
              <p>
                {" "}
                Developed for a robotics competition, our team utilized CAD
                design and various machine tools such as lathes, mills, and
                waterjets to create a high-speed four-bar linkage mechanism with
                optimized gears for torque control. The system's PID control
                ensures rapid and accurate responses, enhancing both efficiency
                and competitive performance.{" "}
              </p>
              <video width="100%" controls>
                <source
                  src={`${process.env.PUBLIC_URL}/linkage_demo.mp4`}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
            <div>
              <h1 className="title">Relational Database Emulator </h1>{" "}
              {/* SECOND PROJECT */}
              <p>
                I created a lightweight relational database emulator designed to
                facilitate efficient data storage, manipulation, and retrieval
                for small-scale applications. This project, developed in C++,
                leverages data structures such as vectors, hash tables, and
                binary search trees to enable optimized indexing and searching.
                It provides a command-line interface that supports creating
                tables, inserting and deleting rows, and performing conditional
                queries and join operations using a simplified SQL-like syntax.
                By incorporating these data structures, this database emulator
                ensures quick access and efficient data management for a
                multitude of applications.
                <p>{""}</p>
                <img
                  src="/relationaldatabase.jpg"
                  alt="Visual depiction of a relational database"
                />
              </p>
            </div>
            <div>
              <h1 className="title"> REM Sleep Detection Mask</h1>{" "}
              {/* THIRD PROJECT */}
              <p>
                I engineered the first working prototype of this device, which
                was initially a theoretical concept of sleep monitoring created
                by a senior design group. It was designed to break traditional
                methods of sleep monitoring. This REM Sleep Mask uses infrared
                sensors to detect rapid eye movements and an accelerometer to
                monitor head movements, providing a comprehensive analysis of
                sleep quality in a design that prioritizes safety, comfort, and
                precision.
              </p>
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/mask1.JPG"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <p>
                      Components here show a Seeeduino XIAO microcontroller, an
                      MPU6050 accelerometer, a 400 mAH lithium-polymer battery,
                      and an SD card reader for data storage.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/mask2.JPG"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <p>
                      Components here show TCRT5000 sensors to detect eye
                      movement and dim LED lights for more accurate sensor
                      detection. All components are indentented within a custom
                      designed 3D printed casing.
                    </p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/mask5.jpg"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/mask4.jpg"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src="/mask6.jpg"
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <p></p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
            <div>
              <h1 className="title">Maze Pathfinder </h1> {/* FOURTH PROJECT */}
              <p>
                In this project, I implemented efficient pathfinding algorithms
                using C++ to navigate a maze-like castle. I designed both
                queue-based and stack-based routing schemes to guide a character
                from a starting position to a target destination while avoiding
                obstacles and utilizing warp pipes for inter-room travel. The
                queue-based scheme employed a breadth-first search to ensure the
                shortest path, while the stack-based scheme used a depth-first
                search for potentially more memory-efficient exploration. This
                project emphasized handling complex environments, ensuring
                efficient execution for large maps, and providing accurate and
                reliable navigation solutions within a specified time
                constraint. This comprehensive approach enhanced my
                understanding and application of fundamental data structures and
                algorithms in practical scenarios.
                <p>{""}</p>
                <img
                  className="d-block w-100"
                  src="/mazefinder.png"
                  alt="Visual depiction of a maze path finder"
                />
              </p>
            </div>{" "}
            <div>
              <h1 className="title">Smart Calorie Scale </h1>{" "}
              {/* FOURTH PROJECT */}
              <p>
                I made the Smart Calorie Scale to assist in managing dietary
                intake by accurately measuring and converting the weight of food
                items into caloric content. Built around a precision load scale
                and powered by an Arduino Nano, the scale features a
                user-friendly button interface that allows for easy selection of
                food types, conversion between grams and calories, and
                utilization of a tare function. The device was meticulously
                engineered using SolidWorks for the design, followed by 3D
                printing for a durable housing and a custom-made circuit board
                to support its functionality.
                <p>{""}</p>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/calorie1.jpg"
                      alt="picture of calorie scale"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/calorie2.jpeg"
                      alt="picture of calorie scale"
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/calorie3.jpeg"
                      alt="picture of calorie scale"
                    />
                    <Carousel.Caption>
                      <p>Solidworks CAD</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/calorie4.jpeg"
                      alt="picture of calorie scale"
                    />{" "}
                    <Carousel.Caption>
                      <p>PCB Schematic</p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </p>
            </div>
            <div>
              <h1 className="title">Zombie Defense Game</h1>{" "}
              <p>
                In this project, I created a terminal-based zombie defense game
                where you eliminate zombies using various priority queue data
                structures. The main goal was to implement efficient data
                structures, including dynamic polymorphism, to manage zombie
                attributes like distance, speed, and health. I designed each
                zombie as a class with specific attributes and behaviors,
                utilizing priority queues to determine the order of actions. I
                focused on developing my skills in reading and implementing
                unfamiliar data structures, debugging complex code, and
                optimizing performance to handle the simulation's demands.
              </p>
              <img
                className="d-block w-100"
                src="/defense.jpg"
                alt="Visual depiction of a maze path finder"
              />
            </div>
            <div>
              <h1 className="title">Robotics Competition RMP</h1>{" "}
              <p>
                In this project, my group and I created a RMP (Robotic Mobility
                Platform) which was meticulously designed using SolidWorks CAD
                and fabricated from scratch using precision tools such as a
                mill, lathe, and waterjet. The platform features a gear-driven
                lever mechanism, specifically engineered to lift a flag and
                flick light switches during a robotics competition. This
                mechanism, which provides precise and reliable motion, was
                crucial for successfully completing the competition's tasks.
              </p>
              <Carousel>
                <Carousel.Item>
                  <img className="d-block w-100" src="/rmp.jpg" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/rmp2.jpg" />
                </Carousel.Item>
                <Carousel.Item>
                  <img className="d-block w-100" src="/rmp3.jpg" />
                </Carousel.Item>
              </Carousel>
            </div>
            {/* <div>
              <h1 className="title">Euchre</h1>{" "}
            </div> */}
          </div>
        </div>
      </HelmetProvider>
    </div>
  );
};
